import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledSmallPicture = styled.div`
    .small-picture {
        position: relative;
    }
    img {
        height: 135px;
        width: 93.5vw;
        margin: 1.5em 0em;
        border-radius: 20px;
    }
    .small-underlayer {
        opacity: 0.7;
        filter: blur(41px);
        border-radius: 20px;
        position: absolute;
        top: 1em;
        left: 1em;
        width: 90%;
        height: 226px;
        border-radius: 20px;
    }
    @media ${device.tablet} {
        img {
            height: 205px;
            width: 550px;
        }
        .small-underlayer {
            opacity: 0.7;
            filter: blur(41px);
            border-radius: 20px;
            position: absolute;
            top: 1em;
            left: 1em;
            width: 90%;
            height: 226px;
            border-radius: 20px;
        }
    }
    @media ${device.laptop} {
        img {
            height: 324px;
            width: 915px;
        }
    }
    @media ${device.laptopL} {
        img {
            height: 324px;
            width: 954px;
        }
    }
`;

export const SmallPicture: React.FC = ({ children }) => {
    return (
        <StyledSmallPicture>
            <div className="small-picture">{children}</div>
        </StyledSmallPicture>
    );
};
