import React,{ useState, createContext, FC, useContext } from 'react';

type UserContextType={
    active:boolean,
    setActive:React.Dispatch<React.SetStateAction<boolean>>
}

interface NavProps {
  children:React.ReactNode
}

export const siteContext = createContext<UserContextType>({active: false, setActive: (active) => !active});

export const SiteContextProvider = ({ children }:NavProps) => {
    const [active, setActive] = useState<boolean>(false);
    return <siteContext.Provider value={{active, setActive}}>{children}</siteContext.Provider>;
};

