import { SmallPicture } from "../../../../src/components/SmallPicture";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as React from 'react';
export default {
  SmallPicture,
  getImage,
  GatsbyImage,
  Link,
  React
};