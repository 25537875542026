import React,{ cloneElement } from 'react';
//import {DefaultLayout} from './src/layouts/DefaultLayout';
import { SiteContextProvider } from './src/layouts/SiteContextProvider';
// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
    return <SiteContextProvider>{element}</SiteContextProvider>;
};

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    //const Layout = element.type.Layout ?? DefaultLayout;
    return element
};