exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-meet-the-doctors-tsx": () => import("./../../../src/pages/about-us/meet-the-doctors.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-the-doctors-tsx" */),
  "component---src-pages-about-us-meet-the-hygienists-tsx": () => import("./../../../src/pages/about-us/meet-the-hygienists.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-the-hygienists-tsx" */),
  "component---src-pages-about-us-our-staff-tsx": () => import("./../../../src/pages/about-us/our-staff.tsx" /* webpackChunkName: "component---src-pages-about-us-our-staff-tsx" */),
  "component---src-pages-about-us-payments-tsx": () => import("./../../../src/pages/about-us/payments.tsx" /* webpackChunkName: "component---src-pages-about-us-payments-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-your-first-visit-tsx": () => import("./../../../src/pages/your-first-visit.tsx" /* webpackChunkName: "component---src-pages-your-first-visit-tsx" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

